<template>
  <div style="width: 100%;">
    <div class="d-flex flex-column">
      <h3 v-if="stocktakingEvent && stocktakingEvent.status !==2">Stocktaking 
        <v-progress-circular indeterminate color="green" v-if="loading"/>
        <span> Event #{{ stocktakingEvent.id }}</span>
      </h3>
      <span v-if="stocktakingEvent && stocktakingEvent.status !==2">
        <h1>{{this.instruction}}</h1>
        <span>
          <v-progress-circular
            indeterminate
            color="green"
            v-if="loading"
        ></v-progress-circular>
        </span>
        <span v-if="!product && this.instruction === this.steps[0]">
          <span class="d-flex flex-column">
            <v-card outlined>
              <v-card-text>
                <span class="d-flex flex-row align-center">
                </span>
                <span class="d-flex flex-row align-center justify-center">
                  <v-text-field style="width: 100%;" v-model="productSearchTerm" @change="searchProduct()" :loading="loadingSearchProduct" label="Search Product" outlined></v-text-field>
                </span>
                <p v-if="noSearchResults">No search results were found.</p>
                <span v-if="productSearchResults&&productSearchResults.length>0">
                  <span class="d-flex flex-row justify-center align-center">
                    <b>Found {{productSearchResults.length}} Search Results</b>
                    <v-btn color="info" style="margin-left: 10px;" @click="clearProductSearchResults">Clear</v-btn>
                  </span>
                  <div v-for="item in productSearchResults" :key="item.id">
                    <span class="d-flex flex-row justify-space-between" style="align-text: left; background-color: rgba(0,0,0,0.05); padding: 10px; margin-top: 6px; border-radius: 7px;">
                      <div class="d-flex flex-column justify-space-between">
                        <span class="d-flex flex-row justify-space-between">
                          <b style="text-align: left;">{{item.Brand?`${item.Brand.name} `:''}} {{item.name}}</b>
                        </span>
                        <span class="d-flex flex-column align-start">
                          <span><b>SP: ${{item.regularPrice}}</b>, <span v-if="isAllowed('product', 'viewCostPrice')">PP: ${{item.salePrice}}</span></span>
                          <span><b>QTY: {{item.ProductLocationJoins.find(x => x.userBranch===true)?item.ProductLocationJoins.find(x => x.userBranch===true).available:0}}</b><span v-if="getGlobalValue('showBranchQuantities')==='true'">[{{item.ProductLocationJoins.reduce((total, curr) => total+parseInt(curr.available), 0)}}]</span></span>
                          <!-- <p>{{item}}</p> -->
                          <span>ID: {{item.id}}, SKU:{{item.sku}}</span>
                        </span>
                      </div>
                      <v-btn fab color="success" @click="goToProduct(item)" x-small><v-icon>mdi-chevron-right</v-icon></v-btn>
                    </span>
                  </div>
                </span>
              </v-card-text>
            </v-card>
          </span>
        </span> 
        <span v-if="product">
          <v-card outlined>
            <v-card-text>
              <span class="d-flex flex-row align-start justify-space-between">
                <span>
                  <h3>{{product.name}}</h3>
                  ID: {{this.product.id}} |  SKU: {{this.product.sku}}
  <!--                <h5>In Stock: {{product.totalQuantity}}</h5>-->
  <!--                <h5>Available: {{product.totalAvailable}}</h5>-->
                  <!-- <h5 v-if="product.deliveryValues.length>0">Pending Delivery: {{product.deliveryValues[0].quantity}}</h5> -->
                </span>
                <v-btn @click="removeProduct" color="error" fab x-small><v-icon>mdi-close</v-icon></v-btn>
              </span>
              <hr>
              <br>
              <span v-if="product">
                <v-text-field outlined :min="0" :rules="quantityRules" label="Enter the Counted Quantity" v-model="product.count" type="number"/>
  
                <span class="text-center" v-if="locationData.locationId && product.changeType!=='tempQuantity'">
                  <h2>Current: {{locationData.locationQuantity}}</h2>
                  <h2>New: {{getNewQuantity()}}</h2>
                </span>
                <v-row>
                  <v-col>
                    <v-textarea outlined v-model="product.notes" label="Notes"/>
                  </v-col>
                </v-row>
              </span>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn block color="success" :loading="confirmButtonLoading" @click="saveProductQuantities">Confirm</v-btn>
            </v-card-actions>
          </v-card>
        </span>
        <span class="mt-4">
          <span class="d-flex flex-column">
            <v-card outlined>
              <v-card-text>
                <h3>My Product Count Log</h3>
                <span v-for="(count, index) in stocktakingEvent.ProductCounts" :key="index" class="d-flex flex-row justify-space-between">
                  <span>
                    <span class="mr-3">{{ index+1 }}</span>
                    <span v-if="count.Product">PL-{{count.productId}} {{ count.Product.name }}</span>
                    <span v-else>Reload page to get product info.</span>
                  </span>
                  <span>{{ count.count }}</span>
                </span>
              </v-card-text>
            </v-card>
          </span>
        </span>
      </span>
      <span v-else>
        <h3>No stocktaking events available.</h3>
      </span>
    </div>
    <v-snackbar style="margin-bottom: 60px;" v-model="snackObj.state" :timeout="3000" :color="snackObj.color">
      {{ snackObj.text }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snackObj.state = false">Close</v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
  import axios from 'axios';
  import scanSystem from '../../plugins/scanSystem';
  import {mapGetters, mapMutations} from "vuex";

  export default {
    data () {
      return {
        instruction: "",
        quantityRules: [
          v => v>=0 || 'Must be more than or equal 0'
        ],
        confirmButtonLoading: false,
        stocktakingEvent: {},
        loading: false,
        product: undefined,
        snackObj: {
          state: false,
          color: '',
          text: ''
        },
        imgObj: {
          isPhotoTaken: false,
          flash: false,
          addPhotoLoader: false,
          snapAndSaveLoader: false,
          photoToDisplay: '',
        },

        locationData: {
          quantity: 0,
          locationId: null,
          locationQuantity: 0
        },

        quantityChangeTypes: [
          {name: "Replace the quantity", value: "absolute"}, {name: "Add to the current quantity", value: "relative"}, {name: "Stop using temporary quantities", value: "tempQuantity"}
        ],
        steps: ["1. Scan a Barcode or Search Product", "Scan Received...", "2. Count and Report Quantities"],
        locations: [],
        productSearchTerm: '',
        loadingSearchProduct: false,
        productSearchResults: [],
        noSearchResults: false
      }
    },
    async mounted(){
      try {
        this.loading = true
        let rooms = await axios.get(`${this.getEndpoint}/api/locations/groupNoSystem`);
        if(rooms.data.error) throw rooms.data.error
        this.locations = rooms.data.data;
        this.instruction = this.steps[0]
        
        let res = await axios.get(`${this.getEndpoint}/api/stocktakingEvents/active/`)
        if(res.data.error) throw res.data.error
        this.stocktakingEvent = res.data.data

        console.log("dfd",this.stocktakingEvent)

      // this.product = await this.lookupProduct('810036021012', 'SKU')
      // this.instruction = this.steps[1]
      // this.loading = false
      } catch (error) {
        console.log(error)
        this.snack(error)
      } finally {
        this.loading = false
      }
    },
    watch: {
      scanBus: {
        handler(){
          console.log("Order.vue: ScanBus Changed")
          if(!this.scanBus.data || this.scanBus.data == "" || this.scanBus.data == undefined){
            console.log("Watcher detected that scanbus was reset. Terminating handoff to handler.")
            return
          }
          if(this.scanBus.handler == 'inventory'){
            this.inventoryHandler()
          }
        },
        deep: true
      }
    },
    computed: {
      ...mapGetters(['getEndpoint', 'isAllowed', 'scanBus', 'getGlobalValue']),
      computedQuantityChangeTypes: function(){
        return this.quantityChangeTypes.filter(type => type.value!=='tempQuantity' || (type.value==='tempQuantity' && this.product.metadata.tempQuantity===true))
      }
    },
    methods: {
      ...mapMutations([
        'setScanBus',
        'resetScanBus',
      ]),
      snack(text, color=""){
        this.snackObj.text = text;
        this.snackObj.state = true;
        this.snackObj.color = color;
      },
      async saveProductQuantities(){
        try{
          console.log(this.product.count)
          // throw "Pause"
          this.confirmButtonLoading = true;
          if(!this.product) throw "No product selected."

          let obj = {
            productId: this.product.id,
            notes: this.product.notes || '',
            count: this.product.count
          }

          let res = await axios.post(`${this.getEndpoint}/api/stocktakingEvents/productCount/${this.stocktakingEvent.id}`, obj);
          if(res.data.error) throw res.data.error
          
          this.snack("Quantities Updated");

          console.log('The Data', res.data)

          this.stocktakingEvent.ProductCounts.push(res.data.data)

          this.product=undefined;
          this.clearProductSearchResults()
          this.instruction = this.steps[0]
        }
        catch(error){
          console.error(error);
          this.snack(error.msg || error, "error");
        }
        finally{
          this.confirmButtonLoading = false;
        }
      },
      toggleLocationQuantityEdit(){
        this.$forceUpdate();
      },
      async inventoryHandler(){
        try {
          this.loading = true
          this.instruction = this.steps[1]
          this.locationData = {
            quantity: 0,
            locationId: null,
            locationQuantity: 0
          }
          console.log("Inventory Handler Fired")
          let type = scanSystem.validateType(this.scanBus.data)
          let s = this.scanBus.data.replace(/\\\/\/\\=/,'')
          let id = (s.replace(`${type}-`, ''));
          if(type!='INV'&&type!='DN'&&type!='PO'&&type!='VSID'){
            let prod = await this.lookupProduct(id, type)
            if(!prod){
              console.log("Product could not be found at this time.")
              this.snack("Product not found.")
              this.instruction = this.steps[0]
            }else{
              this.product = prod
              await this.loadLocations()
              this.instruction = this.steps[2]
            }
          }
        } catch (error) {
          console.log(error)
          this.snack(error)
        } finally {
          this.setScanBus({data: null})
          console.log('ScanBus Cleared')
          this.loading = false
        }
      },
      calculateTotal(){
        let total = 0;

        for(let branch of this.product.locations){
          for(let room of branch.rooms){
            if(room.enabled) total+=room.quantity;
          }
        }

        return total;
      },
      calculateExcludedTotal(){
        let total = this.product.totalQuantity;

        for(let branch of this.product.locations){
          for(let room of branch.rooms){
            if(!room.enabled) total-=room.quantity;
          }
        }

        return total;
      },
      getNewQuantity(){
        console.log(this.product.changeType)
        if(this.product.changeType==='tempQuantity'){
          return null;
        }
        if(this.product.changeType==='absolute'){
          return isNaN(parseInt(this.locationData.quantity))?0:parseInt(this.locationData.quantity);
        }
        if(this.product.changeType==='relative'){
          return isNaN(parseInt(this.locationData.locationQuantity)+parseInt(this.locationData.quantity))?0:parseInt(this.locationData.locationQuantity)+parseInt(this.locationData.quantity);
        }
        return null
      },
      changeQty(location, field='quantity'){
        if(location[field]>=0){
          location[field]=parseInt(location[field]);
          this.$forceUpdate();
        }
        this.productLocationChange();
      },
      productLocationChange(){
        let found = this.product.locations.find(x => x.locationId===this.locationData.locationId);
        if(found){
          this.locationData.locationQuantity = found.quantity || 0;
        }
        this.product.valid = this.checkProductLocationDialog();
        this.$forceUpdate();
      },
      checkProductLocationDialog(){
        if(!this.product) return false;
        if(!this.product.changeType) return false;
        if(this.product.changeType==='tempQuantity') return true;
        if(!this.locationData.locationId) return false;
        return true;
      },
      async lookupProduct(s, type){
        try {
          let res;
          if(type === "SKU")
            res = await axios.get(`${this.getEndpoint}/api/products/bySKU/${s}`)
          if(type === "PL")
            res = await axios.get(`${this.getEndpoint}/api/products/${s}`)
          if(res.data.error) throw res.data.error
          if(!res.data.data) throw 'External barcode is not in VIMS DB.'
          return res.data.data
        } catch (error) {
          console.error(error)
          this.snack(error.msg || error, "error")
        }
      },
      removeProduct(){
        this.instruction = this.steps[0]
        this.product = undefined
        this.variableProductLocationRadio = null;
        this.clearProductSearchResults()
        this.locationData = {
          quantity: 0,
          locationId: null,
          locationQuantity: 0
        }
      }, 
      async loadLocations(){
        try{
          if(!this.product) return
          let getLocations = () => {
            let locations = [];
            for(let branch of this.locations){
              let b = {
                id: branch.id,
                name: branch.name,
                rooms: [],
                available: 0,
                quantity: 0
              }
              for(let room of branch.rooms){
                let obj = {
                  locationId: room.id,
                  name: branch.name+" - "+room.name,
                  quantity: 0,
                  enabled: false,
                  branch: b
                }
                locations.push(obj);
              }
            }
            return locations;
          }
          
          this.product.totalQuantity = 0;
          this.product.totalAvailable = 0;
          this.product.locations = getLocations();
          this.product.changeType = null;
          this.product.notes = '';
          this.product.valid = false;
  
          let prj = await axios.get(`${this.getEndpoint}/api/products/${this.product.id}/onlyLocations`);
          if(prj.data.error) throw prj.data.error
  
          if(prj.data.data.metadata.updateLog){
            this.product.metadata.updateLog = prj.data.data.metadata.updateLog;
          }
  
          let branches = prj.data.data.ProductLocationJoins;
          this.product.deliveryValues = prj.data.data.DeliveryJoins;
          for(let branch of branches){
            for(let room of branch.rooms){
              let f2 = this.product.locations.find(x => x.locationId===room.locationId);
              if(f2){
                f2.quantity = room.quantity;
                this.product.totalQuantity+=room.quantity;
              }
            }
          }
        }
        catch(error){
          console.error(error);
          this.snack(error.msg || error, "error");
        }
      },
      async searchProduct(){
        try {
          if(this.productSearchTerm){
            this.loadingSearchProduct = true;
            let res = await axios.get(`${this.getEndpoint}/api/products/searchByAllVariationsNoLimit?val=${encodeURIComponent(this.productSearchTerm)}`);
            if(res.data.error) throw res.data.error
            this.productSearchResults = res.data.data
            if(this.productSearchResults.length == 0) this.noSearchResults = true
            else this.noSearchResults = false
          }
        } catch (error) {
          console.error(error)
          this.snack(error, "error")
        }finally {
          this.loadingSearchProduct = false;
        }
      },
      clearProductSearchResults(){
        this.productSearchResults = []
        this.productSearchTerm = ""
        this.noSearchResults = false
      },
      watchInput(){
        if(this.productSearchTerm.length == 0) this.noSearchResults = false
      },
      async goToProduct(prod){
        this.product = prod
        this.product.count = 0
        this.instruction = this.steps[2]
      }  
    },
  }
</script>
